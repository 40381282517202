<template>
  <div>
    <isHeader ref="isHeader" />
    <div
      ref="scrollBox"
      style="height: 100vh; overflow-y: auto; overflow-x: hidden"
    >
      <commonTop
        :info="{
          title: '智慧宣教管理平台',
          desc: '借助互联网、物联网及人工智能技术，以专业随访知识库为基础，打造以医患沟通、院后随访和健康宣教为核心的一体化服务平台。',
        }"
        :img="require('@/assets/xuwei/5e9c85f8dce8f.jpg')"
      >
        <!-- 插槽 -->
        <!-- <button>在线咨询</button> -->
        <button @click="$store.commit('changeAppointModal', true)">
          预约体验
        </button>
      </commonTop>
      <!--  -->
      <isTab current="智慧宣教管理平台" :info="info" />
      <isFooter />
    </div>
  </div>
</template>

<script>
import isHeader from "@/components/header/index.vue";
import isFooter from "@/components/footer/index.vue";
import commonTop from "@/components/commonTop/index.vue";
import isTab from "@/components/ProductTab/tab.vue";
export default {
  name: "SmartEducation",
  data() {
    return {
      info: {
        partOne: {
          title: "产品设计理念",
          sub: "聚焦业务需求  深度共建宣教管理平台",
          swiper: [
            {
              bg: require("@/assets/SmartEducation/01.png"),
              icon: `
                            <svg width="100%" height="100%" viewBox="0 0 88 92" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M62.5 85.5H21.5C20.5 85.5 19.5 84.5 19.5 83.5V16.5C19.5 15.5 20.5 14.5 21.5 14.5H72C73 14.5 74 15.5 74 16.5V58.5" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
                            <path d="M5.5 21V3.5C5.5 2.5 6.5 1.5 7.5 1.5H64C65 1.5 66 2.5 66 3.5V14.5" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
                            <path d="M19.5 78.5H7C6 78.5 5 77.5 5 76.5V40.5" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
                            <path d="M29.5 30H64" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
                            <path d="M29.5 43H64" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
                            <path d="M29.5 55.5H40.5" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
                            <path d="M0 30.5H10.5" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
                            <path d="M5.49805 25.5V36" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
                            <path d="M82.5 82.5C81.5 82.5 77.5 82.5 76 83C75 83.5 73.5 84 72.5 85C72 86 70 89.5 70 89.5H65C65 89.5 63.5 86 62.5 85.5C61.5 84.5 60.5 84 59 83.5C57.5 83 53.5 83 52.5 83L50 79C50.5 78.5 53 74 53 72C53 70 50.5 65.5 50 65L52.5 61C53.5 61 57.5 61 59 60.5C60 60 61.5 59.5 62.5 58.5C63.5 57.5 65 54.5 65 54.5H70C70 54.5 71.5 58 72.5 58.5C73.5 59.5 74.5 60 76 60.5C77.5 61 81.5 61 82.5 61L85 65C84.5 65.5 82 70 82 72C82 74 84.5 78.5 85 79L82.5 82.5Z" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
                            <path d="M72.001 75.5C71.001 76.5 69.501 77.5 67.501 77.5C66.001 77.5 64.501 77 63.001 75.5C62.001 74.5 61.001 73 61.001 71C61.001 69.5 61.501 68 63.001 66.5C64.001 65.5 65.501 64.5 67.501 64.5C69.001 64.5 70.501 65 72.001 66.5C73.001 67.5 74.001 69 74.001 71C73.501 72.5 73.001 74 72.001 75.5Z" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
                            </svg>
                            `,
              name: "随访路径规划",
            },
            {
              bg: require("@/assets/SmartEducation/02.png"),
              icon: `
                            <svg width="100%" height="100%" viewBox="0 0 89 99" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M28.123 20.8473C31.4307 9.60114 37.3846 2.32422 44 2.32422C54.5846 2.32422 63.8461 23.4935 63.8461 49.2935C63.8461 53.2627 63.8461 56.5704 63.1846 59.8781" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
                            <path d="M59.8774 77.7384C56.5697 88.9845 50.6158 96.923 44.0005 96.923C33.4158 96.923 24.1543 75.7538 24.1543 49.9538C24.1543 45.9845 24.3385 41.8077 25 38.5" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
                            <path d="M11.5843 48.631C3.64581 40.031 -0.323424 31.431 2.98427 25.4772C8.27658 16.2156 31.4304 18.8618 53.9227 32.0925C57.2304 34.0772 60.5381 36.0618 63.1843 38.0464" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
                            <path d="M75.7539 48.6309C84.3539 57.8924 88.3231 67.1539 85.0154 72.4463C79.7231 81.7078 56.5693 79.0616 34.077 65.8309C30.7693 64.5078 27.4616 62.5232 24.8154 60.5386" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
                            <path d="M44 71.1221C47.3077 69.799 50.6154 67.8144 53.9231 65.8297C76.4154 52.599 90.3077 34.7374 85.0154 25.4759C81.7077 20.1836 72.4462 18.8605 60.5385 21.5067" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
                            <path d="M44.0004 26.8008C40.6927 28.7854 37.385 30.1085 34.0773 32.0931C25.4773 37.3854 17.5389 43.3392 12.2465 49.2931C3.64655 57.8931 -0.322686 67.1546 2.98501 73.1085C6.2927 79.0623 15.5542 79.7239 28.1235 77.0777" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
                            <path d="M51.2771 39.3691H34.0771V58.5538H51.2771V39.3691Z" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
                            <path d="M42 40.0312V46.6466L44.6462 44.662L45.9692 46.6466V40.0312H42Z" fill="white"/>
                            </svg>
                            `,
              name: "智能知识库",
            },
          ],
        },
        partTwo: {
          title: "产品功能介绍",
          sub: "通过智能化手段赋能健康宣教  推动医疗行业大数据变革",
          list: [
            {
              title: "健康宣教方案配置与管理",
              desc: "系统支持为不同病种、不同病程、不同阶段的慢病患者提供量身定制的宣教服务方案。",
            },
          ],
          right_img: require("@/assets/SmartEducation/missionManage.png"),
        },
      },
    };
  },
  mounted() {
    let that = this;
    this.$refs.scrollBox.addEventListener("scroll", function (e) {
      if (e.target.scrollTop > 80) {
        that.$refs.isHeader.setScrollBg(true);
      } else {
        that.$refs.isHeader.setScrollBg(false);
      }
    });
  },
  components: {
    commonTop,
    isHeader,
    isFooter,
    isTab,
  },
};
</script>

<style>
</style>